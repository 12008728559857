import React, { useState } from "react";
import Filters from "views/shared/Filters";
import { Container, Card, CardBody } from "reactstrap";
import ChartContainer from "./ChartsContainer.jsx";
import { useParams } from "react-router-dom";

function ProductionData() {
  const params = useParams();
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  // User's timezone
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Define start and end times
  const startTime = "06:00:00"; // 6 AM
  const endTime = "18:00:00"; // 6 PM

  // Convert dates to YYYY-MM-DD format
  const startDateString = yesterday.toISOString().split("T")[0]; // "YYYY-MM-DD"
  const endDateString = today.toISOString().split("T")[0]; // "YYYY-MM-DD"

  // Combine with the fixed times and convert to ISO format
  const startDateISO = new Date(
    `${startDateString}T${startTime}Z`
  ).toISOString();
  const endDateISO = new Date(`${endDateString}T${endTime}Z`).toISOString();

  // Set initial state
  const [filtersData, setFiltersData] = useState({
    start_date: startDateISO,
    end_date: endDateISO,
    timezone: userTimezone,
    start_time: "06:00",
    end_time: "18:00",
    recipe: "All",
    product: "All",
    tool: "All",
  });

  const handleApplyFilters = (filterData) => {
    setFiltersData({ ...filterData });
  };

  return (
    <Container fluid>
      <Card className="m-3 mb-4">
        <CardBody className="rounded px-5 py-4">
          <Filters
            filtersData={filtersData}
            handleApplyFilters={handleApplyFilters}
            clientId={params.clientId}
            machineId={params.machineId}
          />
        </CardBody>
      </Card>
      <ChartContainer
        filtersData={filtersData}
        setFiltersData={setFiltersData}
        clientId={params.clientId}
        machineId={params.machineId}
      />
    </Container>
  );
}

export default ProductionData;
