import React, { useState, useEffect } from "react";
import AuthService from "services/auth.service";
import { CircularProgress, Grid } from "@material-ui/core";
import { Card, CardBody, CardHeader, FormGroup } from "reactstrap";
import axios from "axios";
import { baseUrl } from "../../constants/url";
import authHeader from "../../services/auth-header";
import Select from "react-select";
// import MachinesListing from "views/DataPages/MachinesListing/MachinesListing";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import DaySummary from "views/DataPages/PerDaySummary/DaySummary";

const customStyles = {
  input: (base) => ({
    ...base,
    color: "white",
  }),
  singleValue: (base) => ({
    ...base,
    color: "white",
  }),
  placeholder: (base) => ({
    ...base,
    color: "white",
  }),
  control: (base, state) => ({
    ...base,
    // background: "#023950",
    // background: "#737977",
    background: "#19312e",
    // match with the menu
    borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    // Overwrittes the different states of border
    // borderColor: state.isFocused ? "yellow" : "green",
    borderColor: state.isFocused ? "#0ca6f2" : "#8898aa",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#0ca6f2" : "#0ca6f2",
    },
  }),
  menu: (base) => ({
    ...base,
    // background: "#737977",
    background: "#19312e",
    color: "white",
    // override border radius to match the box
    border: "1px solid #8898aa",
    borderRadius: 5,
    // kill the gap
    marginTop: 0,
  }),
  menuList: (base, state) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
    "::-webkit-scrollbar": {
      display: "none",
    },
    // overflow: 'hidden'
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      // backgroundColor: isFocused ? "#2462ad" : null,
      backgroundColor: isFocused ? "#0ca6f2" : null,
      color: isFocused ? "black" : "white",
      // color: "#333333",
    };
  },
};

function AdminClientSelection({ setClientName, clientId, setClientId }) {
  const user = AuthService.getCurrentUser();
  // const [eIndexName, setEIndexName] = useState(user.prodIndex);
  const [clientsList, setClientsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (user.role === "admin") {
      setIsLoading(true);
      axios
        .get(`${baseUrl}/api/admin/client-names`, {
          headers: authHeader(),
        })
        .then((res) => {
          const option = res.data.clients.map((item) => {
            return {
              label: item.name,
              value: item.id,
              subscription: item.subscriptionLabel,
            };
          });
          setClientsList(option);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  }, [user.role]);

  const handleClientSelect = (value) => {
    setClientId(value);
  };

  return clientId && location.pathname.includes("/admin/day-summary") ? (
    <DaySummary clientId={clientId} />
  ) : user.role === "admin" ? (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: "50vh" }}
    >
      <Grid item xs={10} lg={4}>
        {isLoading ? (
          <div className="text-center">
            <CircularProgress size={60} />
          </div>
        ) : (
          <Card className="card-stats">
            <CardHeader className="text-light h3">
              Please select Client for which you want to view data:
            </CardHeader>
            <CardBody>
              <FormGroup>
                <label className="form-control-label textWhite d-block">
                  Select Client:
                </label>
                <Select
                  placeholder="Select Client..."
                  name="clientId"
                  value={{ label: clientId }}
                  onChange={(e) => {
                    handleClientSelect(e.value);
                    setClientName(e.label);
                  }}
                  styles={customStyles}
                  // options={clientsList}
                  options={clientsList.filter(
                    (el) => el.subscription === "premium"
                  )}
                />
              </FormGroup>
            </CardBody>
          </Card>
        )}
      </Grid>
    </Grid>
  ) : (
    <>Not Allowed</>
  );
}

export default AdminClientSelection;
