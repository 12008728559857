import React from "react";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import AuthService from "services/auth.service";

const AdminSelectionPage = () => {
  const history = useHistory();
  const user = AuthService.getCurrentUser();

  if (user.role === "user") {
    history.push("/admin/dashboard");
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      style={{ minHeight: "65vh", paddingInline: "5%" }}
    >
      {user.role === "user" ? null : (
        <Grid
          item
          xs={11}
          md={5}
          className="btn m-2 py-5 btn-custom-hover neumorphic"
          onClick={() => {
            history.push("/admin/panel");
          }}
        >
          <div className="h1 text-white text-hover">Management Panel</div>
        </Grid>
      )}
      <Grid
        item
        xs={11}
        md={5}
        className="btn m-2 py-5 btn-custom-hover neumorphic"
        onClick={() => {
          history.push("/admin/dashboard");
        }}
      >
        <div className="h1 text-white text-hover">MiAssist Dashboard</div>
      </Grid>

      {(user.role === "admin" || user.subscriptionLabel === "premium") && (
        <Grid
          item
          xs={11}
          md={5}
          className="btn m-2 py-5 btn-custom-hover neumorphic"
          onClick={() => {
            history.push("/admin/day-summary");
          }}
        >
          <div className="h1 text-white text-hover">Per Day Summary</div>
        </Grid>
      )}
    </Grid>
  );
};

export default AdminSelectionPage;
