import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import AuthService from "../../services/auth.service";
import { CircularProgress } from "@material-ui/core";

const ForgotPassword = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault()
    setIsLoading(true);
    setErrorMessage("");
    setSuccessMessage("");
    AuthService.forgotPassword(email)
      .then(
        (res) => {
          setSuccessMessage(
            "A reset password link has been sent on the provided email!"
          );
          setIsLoading(false);
        }
      )
      .catch((err) => {
        setErrorMessage(
          err.message === "Network Error"
            ? "Server error!"
            : err.response.data.message
        );
        setIsLoading(false);
      });
  };

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="h1 text-center mb-4" style={{ color: "#0ca6f2" }}>
              <div>Forgot Password</div>
            </div>
            {errorMessage && (
              <div className="h4 text-center mb-4" style={{ color: "#ff0000" }}>
                <div>{errorMessage}</div>
              </div>
            )}
            {successMessage ? (
              <div className="h4 text-center mb-4" style={{ color: "#ffffff" }}>
                <div>{successMessage}</div>
              </div>
            ) : (
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText className="textBg">
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email"
                      type="email"
                      className="textBg"
                      autoComplete="new-email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>

                <div className="text-center">
                  <Button
                    className="mt-4 w-50"
                    style={{ backgroundColor: "#0ca6f2", border: "none" }}
                    type="button"
                    onClick={handleSubmit}
                  >
                    {isLoading ? (
                      <>
                        <CircularProgress size={15} />
                      </>
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </div>
              </Form>
            )}
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <span
              className="h3"
              style={{ cursor: "pointer", color: "#0ca6f2" }}
              onClick={(e) => history.push("/auth/login")}
            >
              <p><i className="ni ni-bold-left mr-2" />Back to Login</p>
            </span>
          </Col>
          {/* <Col className="text-right" xs="6">
            <span
              className="h3"
              style={{ cursor: "pointer", color: "#0ca6f2" }}
              onClick={(e) => history.push("/auth/register")}
            >
              <small>New User? Register</small>
            </span>
          </Col> */}
        </Row>
      </Col>
    </>
  );
};

export default ForgotPassword;
