import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Nav,
  NavItem,
  Input,
} from "reactstrap";
import axios from "axios";
import Plot from "react-plotly.js";
import {
  baseUrl,
  last_week,
  last_month,
  last_year,
  last_8_hours,
  last_16_hours,
  last_24_hours,
} from "constants/url";
import authHeader from "services/auth-header";
import moment from "moment";

export default function MachineStateBarChart({
  filtersData,
  clientId,
  machineId,
  setFiltersData,
}) {
  const [barChartData, setBarChartData] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [duration, setDuration] = useState("");

  const fetchData = (filterObject) => {
    setIsLoading(true);
    axios
      .get(`${baseUrl}/api/admin/machine-state-bar-chart`, {
        params: { ...filterObject, clientId, machineId },
        headers: authHeader(),
      })
      .then((res) => {
        const barData = {
          data: [
            {
              type: "bar",
              x: res.data.barChartData.map((d) => d.x),
              y: res.data.barChartData.map((d) => d.y),
              marker: { color: "#36a2eb" },
            },
          ],
          layout: {
            // title: "Recipe Usage Distribution",
            xaxis: {
              title: "Recipe",
              tickangle: -45,
            },
            yaxis: { title: "Count" },
            height: 300,
            margin: { l: 50, r: 50, t: 50, b: 100 },
            paper_bgcolor: "rgb(36,41,40)",
            plot_bgcolor: "rgb(36,41,40)",
            font: {
              family: "Open Sans, sans-serif",
              size: 13,
              color: "white",
            },
          },
        };
        setBarChartData(barData);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const onChange = (event) => {
    const value = event.target.value;
    setDuration(value);
    if (value === "") {
      fetchData(filtersData);
    } else if (value === last_week) {
      const start = moment().subtract(1, "weeks").utcOffset(0, true).format();
      const end = moment().utcOffset(0, true).format();
      fetchData({
        ...filtersData,
        start_date: start,
        end_date: end,
      });
    } else if (value === last_month) {
      const start = moment().subtract(1, "months").utcOffset(0, true).format();
      const end = moment().utcOffset(0, true).format();
      fetchData({
        ...filtersData,
        start_date: start,
        end_date: end,
      });
    } else if (value === last_year) {
      const start = moment().subtract(1, "years").utcOffset(0, true).format();
      const end = moment().utcOffset(0, true).format();
      fetchData({
        ...filtersData,
        start_date: start,
        end_date: end,
      });
    } else if (value === last_24_hours) {
      const start = moment().subtract(1, "days").utcOffset(0, true).format();
      const end = moment().utcOffset(0, true).format();
      fetchData({
        ...filtersData,
        start_date: start,
        end_date: end,
      });
    } else if (value === last_16_hours) {
      const start = moment().subtract(16, "hours").utcOffset(0, true).format();
      const end = moment().utcOffset(0, true).format();
      fetchData({
        ...filtersData,
        start_date: start,
        end_date: end,
      });
    } else if (value === last_8_hours) {
      const start = moment().subtract(8, "hours").utcOffset(0, true).format();
      const end = moment().utcOffset(0, true).format();
      fetchData({
        ...filtersData,
        start_date: start,
        end_date: end,
      });
    }
  };

  useEffect(() => {
    setDuration("");
    fetchData(filtersData);
  }, [filtersData]);

  const config = {
    displayModeBar: false, // this is the line that hides the bar.
    // responsive: true,
  };

  return (
    <Card className="h-100">
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <h2 className="mb-0" style={{ color: "#0ca6f2" }}>
              Machine State Distribution
            </h2>
          </div>
          <div className="col">
            <Nav className="justify-content-end" pills>
              <NavItem>
                <Input
                  placeholder="Duration"
                  name="duration"
                  type="select"
                  value={duration}
                  className="textBg"
                  bsSize="sm"
                  style={{ color: "#000000" }}
                  onChange={onChange}
                >
                  {[
                    "",
                    last_week,
                    last_month,
                    last_year,
                    last_8_hours,
                    last_16_hours,
                    last_24_hours,
                  ].map((option, idx) => {
                    return (
                      <option key={idx} value={option}>
                        {option}
                      </option>
                    );
                  })}
                </Input>
              </NavItem>
            </Nav>
          </div>
        </Row>
      </CardHeader>
      <CardBody className="p-0 h-100">
        {isLoading ? (
          <div className="m-5">Loading...</div>
        ) : barChartData.data ? (
          <div className="chart h-100">
            <div
              className="chart-container"
              style={{
                margin: "auto",
                height: "100%",
              }}
            >
              <Plot
                data={barChartData.data}
                className="w-100 h-100"
                useResizeHandler
                layout={barChartData.layout}
                config={config}
                onClick={(e) => {
                  console.log("onClick", e.points[0].label);
                  setFiltersData({
                    ...filtersData,
                    recipe: e.points[0].label,
                  });
                }}
              />
            </div>
          </div>
        ) : (
          <div className="m-5">No Data Found</div>
        )}
      </CardBody>
    </Card>
  );
}
