import React, { useState, useEffect, useRef } from "react";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { baseUrl, baseUrlManagement } from "constants/url";
import axios from "axios";
import authHeader from "services/auth-header";
import { Button, Row, Spinner } from "reactstrap";
import MachineDataCard from "./MachineDataCard";
import { Card, CardBody, Col } from "reactstrap";
import MachinesSpeedGraph from "./MachinesSpeedGraph";
import Filters from "views/shared/Filters";
import ColorLegend from "components/ColorLegend";
import TimePicker from "./TimePicker";

const MachinesListing = ({ clientId }) => {
  const history = useHistory();
  // const today = new Date();
  // const yesterday = new Date(today);
  // // yesterday.setDate(yesterday.getDate() - 7);
  // yesterday.setHours(yesterday.getHours() - 8);

  // const [filtersData, setFiltersData] = useState({
  //   // start_date: "2023-01-13T11:35:04.00Z",
  //   start_date: yesterday.toISOString(),
  //   // end_date: "2023-01-13T11:35:04.30Z",
  //   end_date: today.toISOString(),
  // });

  // Get current date and time in local timezone
  const now = new Date();
  const today6AM = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    6,
    0,
    0,
    0
  );


  function getCurrentDateTime(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  }

  // Check if current local time is before 6 AM
  const isBeforeSixAM = now < today6AM;

  const startDate = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() - (isBeforeSixAM ? 1 : 0),
    6,
    0,
    0,
    0
  );

  const endDate = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() - (isBeforeSixAM ? 1 : 0),
    18,
    0,
    0,
    0
  );

  const getLocalDayBounds = () => {
    const today = new Date();
    const offset = today.getTimezoneOffset() * 60000; // Convert offset to milliseconds

    const startOfDay = new Date(today);
    startOfDay.setHours(6, 0, 0, 0);
    const startISO = new Date(startOfDay.getTime() - offset).toISOString();

    const endOfDay = new Date(today);
    endOfDay.setHours(18, 0, 0, 0);
    const endISO = new Date(endOfDay.getTime() - offset).toISOString();

    return { startISO, endISO };
  };

  // Usage:
  const [filtersData, setFiltersData] = useState({
    start_date: getLocalDayBounds().startISO,
    end_date: getLocalDayBounds().endISO,
  });
  console.log("filtersData", filtersData);
  console.log("startDate", startDate);
  console.log("endDate", endDate);

  const [timeRange, setTimeRange] = useState({
    start_time: "",
    end_time: "",
  });
  const handleDateRange = (start_date, end_date) => {
    setFiltersData((prevState) => {
      return {
        ...prevState,
        start_date,
        end_date,
      };
    });
  };
  const [isLoading, setIsLoading] = useState(true);
  const [machinesData, setMachinesData] = useState([]);

  const timerRef = useRef(null);

  const getData = (key, clientMachines) => {
    // const today = new Date();
    // const yesterday = new Date(today);
    // // yesterday.setDate(yesterday.getDate() - 7);
    // yesterday.setHours(yesterday.getHours() - 8);
    axios
      .post(
        `${baseUrl}/api/admin/machinesData`,
        {
          // start_date: today.toISOString(),
          // end_date: today.toISOString(),
          start_date: filtersData.start_date,
          end_date: filtersData.end_date,
          clientId,
          initialReq: key,
          machinesIdArray: clientMachines,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        setMachinesData(res.data.dataArray);
        setIsLoading(false);
        timerRef.current = setTimeout(() => {
          getData("no", clientMachines);
        }, 30000);
      })
      .catch((err) => {
        setMachinesData([]);
        setIsLoading(false);
        timerRef.current = setTimeout(() => {
          getData("no", clientMachines);
        }, 30000);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    let clientMachines = [];
    axios
      .get(`${baseUrl}/api/admin/client_machines/${clientId}`, {
        headers: authHeader(),
      })
      .then((res) => {
        res.data.machines.forEach((item, index) => {
          clientMachines.push({
            machineId: item.machineId,
            img: item.machineType && item.machineType.imagePath,
            line: item.lineName,
          });
        });
        getData("yes", clientMachines);
      })
      .catch((err) => {
        console.log(err);
      });
    return () => {
      clearTimeout(timerRef.current);
    };
  }, [clientId, filtersData]);
  // }, [eIndexName, filtersData]);

  // const handleApplyFilters = (filterData) => {
  //   setFiltersData({ ...filterData });
  // };

  if (isLoading) {
    return (
      // <Grid
      //   container
      //   direction="column"
      //   justifyContent="center"
      //   alignItems="center"
      // >
      //   <Grid item xs={12} className="p-0" style={{ width: "90vw" }}>
      //     <Card className="shadow my-4">
      //       <CardBody className="rounded px-5 py-4">
      //         <Filters
      //           filtersData={filtersData}
      //           handleApplyFilters={handleApplyFilters}
      //         />
      //       </CardBody>
      //     </Card>
      //   </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: "65vh" }}
      >
        <Spinner />
      </Grid>
      // </Grid>
    );
  } else if (machinesData.length > 0) {
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        {/* <ColorLegend /> */}
        <Grid item xs={12} className="p-0" style={{ width: "90vw" }}>
          <Card className="shadow my-4">
            <CardBody className="rounded px-5 py-4">
              {/* <Filters
                filtersData={filtersData}
                handleApplyFilters={handleApplyFilters}
              /> */}
              <Row>
                <Col xs={12}>
                  <TimePicker
                    filtersData={filtersData}
                    handleDateRange={handleDateRange}
                    timeRange={timeRange}
                    setTimeRange={setTimeRange}
                  />
                  <div style={{ marginTop: '1rem' }}>
                    <span className="h3 text-muted">
                      {/* {yesterday.toDateString()} */}
                      {/* {yesterday.toISOString().replace('T', ' ---- ')} */}
                      {/* {getCurrentDateTime(yesterday)} */}
                      Last Updated Time :
                      {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-- &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                      {/* {today.toDateString()} */}
                      {/* {today.toISOString().replace('T', ' ---- ')} */}
                      {' '}
                    </span>
                    <span className="h2 text-muted">
                      {/* {yesterday.toDateString()} */}
                      {/* {yesterday.toISOString().replace('T', ' ---- ')} */}
                      {/* {getCurrentDateTime(yesterday)} */}
                      {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-- &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                      {/* {today.toDateString()} */}
                      {/* {today.toISOString().replace('T', ' ---- ')} */}
                      {' '}
                      {getCurrentDateTime(new Date(filtersData.end_date))}
                    </span>
                  </div>
                </Col>
                {/* <Col
                  className="justify-content-end align-items-center d-flex pt-3 mb-2"
                  xs={12}
                  md={6}
                  xl={4}
                  xxl={2}
                >
                  <Button
                    style={{ backgroundColor: "#a7ce39", border: "none" }}
                    onClick={handleApply}
                  >
                    Apply
                  </Button>
                  <Button
                    className=""
                    color="secondary"
                    onClick={() => {
                      const today = new Date();
                      const yesterday = new Date(today);
                      yesterday.setDate(yesterday.getDate() - 7);
                      setFilterState({
                        start_date: yesterday.toISOString(),
                        end_date: today.toISOString(),
                        recipe: "All",
                        line: "All",
                        kpiMin: 0,
                        kpiMax: 200,
                        searchByRecipe: "",
                      });
                      handleApplyFilters({
                        start_date: yesterday.toISOString(),
                        end_date: today.toISOString(),
                        recipe: "All",
                        line: "All",
                        kpiMin: 0,
                        kpiMax: 200,
                        searchByRecipe: "",
                      });
                    }}
                  >
                    Clear
                  </Button>
                </Col> */}
              </Row>
            </CardBody>
          </Card>
        </Grid>
        {machinesData.map((machine, idx) => {
          return (
            <Grid
              item
              xs={12}
              className="btn btn-custom-hover-machine neumorphic p-0 my-3"
              style={{ width: "90vw" }}
              key={idx}
              onClick={() => {
                // if (machine.Status === "OFFLINE") {
                //   return;
                // }
                history.push(
                  `/admin/productionData/${clientId}/${machine.machineId}`
                );
              }}
            >
              <MachineDataCard data={machine} />
            </Grid>
          );
        })}
        <Col xs="12" className="my-4" style={{ width: "92vw" }}>
          <Card className="card-stats" style={{ height: "100%" }}>
            <CardBody className="p-0">
              <MachinesSpeedGraph title={"Speed/Min"} allData={machinesData} />
            </CardBody>
          </Card>
        </Col>
      </Grid>
    );
  } else {
    return (
      // <Grid
      //   container
      //   direction="column"
      //   justifyContent="center"
      //   alignItems="center"
      // >
      //   <Grid item xs={12} className="p-0" style={{ width: "90vw" }}>
      //     <Card className="shadow my-4">
      //       <CardBody className="rounded px-5 py-4">
      //         <Filters
      //           filtersData={filtersData}
      //           handleApplyFilters={handleApplyFilters}
      //         />
      //       </CardBody>
      //     </Card>
      //   </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: "65vh" }}
        className="h1 text-white"
      >
        No Machines Found
      </Grid>
      // </Grid>
    );
  }
};

export default MachinesListing;
