import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Col, FormGroup, InputGroup, InputGroupText, Row } from "reactstrap";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    // width: "46%",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#cad1d7", // Default border color
      },
      "&:hover fieldset": {
        borderColor: "#cad1d7", // Border color on hover
      },
      "&.Mui-focused fieldset": {
        borderColor: "#a7ce39", // Border color when focused
      },
    },
    "& .MuiOutlinedInput-input": {
      color: "#cad1d7", // Text color
    },
    "& .MuiInputLabel-outlined": {
      color: "#cad1d7", // Label color
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#cad1d7", // Label color when focused
    },
    '& input[type="time"]::-webkit-calendar-picker-indicator': {
      filter: "invert(1)", // This inverts the color, making the icon white
    },
  },
}));

export default function TimePicker({
  handleDateRange,
  filtersData,
  timeRange,
  setTimeRange,
}) {
  const classes = useStyles();

  // useEffect(() => {
  //   const initialStartTime = moment.utc(filtersData.start_date).format("HH:mm");
  //   const initialEndTime = moment.utc(filtersData.end_date).format("HH:mm");

  //   setTimeRange({
  //     start_time: initialStartTime,
  //     end_time: initialEndTime,
  //   });
  // }, [filtersData]);

  useEffect(() => {
    // Use UTC methods to prevent local time conversion
    const startTime = moment(filtersData.start_date).utc().format("HH:mm");
    const endTime = moment(filtersData.end_date).utc().format("HH:mm");

    setTimeRange({
      start_time: startTime,
      end_time: endTime,
    });
  }, [filtersData]);

  // const handleTimeChange = (event) => {
  //   const { id, value } = event.target;
  //   setTimeRange((prevState) => ({
  //     ...prevState,
  //     [id]: value,
  //   }));

  //   const startDate = moment.utc(filtersData.start_date);
  //   const endDate = moment.utc(filtersData.end_date);

  //   if (id === "start_time") {
  //     startDate.set({
  //       hour: value.split(":")[0],
  //       minute: value.split(":")[1],
  //     });
  //     endDate.set({
  //       hour: timeRange.end_time.split(":")[0],
  //       minute: timeRange.end_time.split(":")[1],
  //     });
  //   } else {
  //     startDate.set({
  //       hour: timeRange.start_time.split(":")[0],
  //       minute: timeRange.start_time.split(":")[1],
  //     });
  //     endDate.set({
  //       hour: value.split(":")[0],
  //       minute: value.split(":")[1],
  //     });
  //   }

  //   console.log(startDate.toISOString());
  //   console.log(endDate.toISOString());

  //   handleDateRange(startDate.toISOString(), endDate.toISOString());
  // };

  const handleTimeChange = (event) => {
    const { id, value } = event.target;
    setTimeRange((prevState) => ({
      ...prevState,
      [id]: value,
    }));

    // Create moment objects using the local date from filtersData
    const startDate = moment(filtersData.start_date);
    const endDate = moment(filtersData.end_date);

    // Update the hours and minutes while preserving the date and timezone
    if (id === "start_time") {
      const [hours, minutes] = value.split(":");
      startDate.set({ hours, minutes });
    } else {
      const [hours, minutes] = value.split(":");
      endDate.set({ hours, minutes });
    }

    // Convert back to UTC for the backend
    handleDateRange(startDate.toISOString(), endDate.toISOString());
  };

  return (
    // <div
    //   id="reportrange"
    //   style={{
    //     cursor: "pointer",
    //     width: "100%",
    //   }}
    // >
    //   <FormGroup className="mb-0">
    //     <label className="form-control-label textWhite d-block filter-label-font-size">
    //       Start Time
    //     </label>
    //     <InputGroup style={{ display: "flex", width: "100%" }}>
    //       <TextField
    //         id="start_time"
    //         type="time"
    //         size="small"
    //         value={timeRange.start_time}
    //         onChange={handleTimeChange}
    //         className={classes.root}
    //         inputProps={{
    //           step: 300, // 5 min
    //         }}
    //         variant="outlined"
    //         style={{ flexGrow: 1 }}
    //       />
    //       <InputGroupText
    //         className="textBg"
    //         style={{
    //           border: "none",
    //           backgroundColor: "transparent",
    //           flexShrink: 0,
    //         }}
    //       >
    //         <i className="fas fa-minus" />
    //       </InputGroupText>
    //       <TextField
    //         id="end_time"
    //         type="time"
    //         size="small"
    //         value={timeRange.end_time}
    //         onChange={handleTimeChange}
    //         className={classes.root}
    //         inputProps={{
    //           step: 300, // 5 min
    //         }}
    //         variant="outlined"
    //         style={{ flexGrow: 1 }}
    //       />
    //     </InputGroup>
    //   </FormGroup>
    // </div>

    <div
      id="reportrange"
      style={{
        cursor: "pointer",
        width: "100%",
      }}
    >
      <FormGroup className="mb-0">
        <Row
          className="justify-content-between"
        // style={{ display: "flex", width: "100%", gap: "600px" }}
        >
          <Col xs={12} md={4}>
            <label className="form-control-label textWhite d-block filter-label-font-size">
              Start Time
            </label>
            <TextField
              id="start_time"
              type="time"
              size="small"
              value={timeRange.start_time}
              onChange={handleTimeChange}
              className={classes.root}
              inputProps={{
                step: 300, // 5 min
              }}
              variant="outlined"
              style={{ width: "100%" }}
            />
          </Col>
          <Col xs={12} md={4}>
            <label className="form-control-label textWhite d-block filter-label-font-size">
              End Time
            </label>
            <TextField
              id="end_time"
              disabled
              type="time"
              size="small"
              value={timeRange.end_time}
              onChange={handleTimeChange}
              className={classes.root}
              inputProps={{
                step: 300, // 5 min
              }}
              variant="outlined"
              style={{ width: "100%" }}
            />
          </Col>
        </Row>
      </FormGroup>
    </div>
  );
}
