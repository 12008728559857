import axios from "axios";
import { baseUrl } from "../constants/url";
import authHeader from "services/auth-header";

const distributorList = () => {
  return axios.get(`${baseUrl}/api/admin/distributor`, {
    headers: authHeader(),
  });
};

const clientList = () => {
  return axios.get(`${baseUrl}/api/admin/client`, {
    headers: authHeader(),
  });
};

const subscriptionList = () => {
  return axios.get(`${baseUrl}/api/admin/subscriptions`, {
    headers: authHeader(),
  });
};

const machineTypes = () => {
  return axios.get(`${baseUrl}/api/admin/machineTypes`, {
    headers: authHeader(),
  });
};

const rolesList = () => {
  return axios.get(`${baseUrl}/api/admin/userGroups`, {
    headers: authHeader(),
  });
};

export const modalConfiguration = {
  distributor: [
    {
      label: "Name",
      type: "text",
      name: "name",
      placeholder: "Name",
    },
    {
      label: "Location",
      type: "text",
      name: "location",
      placeholder: "Location",
    },
    {
      label: "Contact Name",
      type: "text",
      name: "contactName",
      placeholder: "Contact Name",
    },
    {
      label: "Contact Email",
      type: "text",
      name: "contactEmail",
      placeholder: "Contact Email",
    },
    {
      label: "Contact Number",
      type: "text",
      name: "contactNumber",
      placeholder: "Contact Number",
    },
  ],
  client: [
    {
      label: "Client Id",
      type: "text",
      name: "clientId",
      placeholder: "Client Id",
    },
    {
      label: "Name",
      type: "text",
      name: "name",
      placeholder: "Name",
    },
    {
      label: "Location",
      type: "text",
      name: "location",
      placeholder: "Location",
    },
    {
      label: "Contact Name",
      type: "text",
      name: "contactName",
      placeholder: "Contact Name",
    },
    {
      label: "Contact Email",
      type: "text",
      name: "contactEmail",
      placeholder: "Contact Email",
    },
    {
      label: "Contact Number",
      type: "text",
      name: "contactNumber",
      placeholder: "Contact Number",
    },
    {
      label: "Distributor",
      type: "select",
      name: "distributorId",
      options: distributorList,
      placeholder: "Distributor",
    },
    {
      label: "Subscription",
      type: "select",
      name: "subscriptionId",
      options: subscriptionList,
      placeholder: "Subscription",
      required: true,
    },
  ],
  machine: [
    {
      label: "Client",
      type: "select",
      name: "clientId",
      options: clientList,
      placeholder: "Client",
      required: true,
    },
    {
      label: "Machine Type",
      type: "select",
      name: "machineTypeId",
      options: machineTypes,
      placeholder: "Machine Type",
      required: true,
    },
    {
      label: "Serial No",
      type: "text",
      name: "machineId",
      placeholder: "Serial No",
      required: true,
    },
    {
      label: "Line",
      type: "text",
      name: "lineName",
      placeholder: "Line",
    },
  ],
  machineType: [
    {
      label: "Label",
      type: "text",
      name: "label",
      placeholder: "Label",
      required: true,
    },
    {
      label: "Machine Type Image",
      type: "file",
      name: "file",
      placeholder: "Machine Type Image",
      required: true,
    },
  ],
  line: [
    {
      label: "Line Name",
      type: "text",
      name: "lineName",
      placeholder: "Line Name",
    },
    {
      label: "Client",
      type: "select",
      name: "clientId",
      options: clientList,
      placeholder: "Client",
    },
    {
      label: "Machine",
      type: "text",
      name: "machineName",
      placeholder: "Machine",
    },
  ],
  user: [
    {
      label: "Username",
      type: "text",
      name: "username",
      placeholder: "Username",
    },
    {
      label: "First Name",
      type: "text",
      name: "firstName",
      placeholder: "First Name",
    },
    {
      label: "Last Name",
      type: "text",
      name: "lastName",
      placeholder: "Last Name",
    },
    {
      label: "Email",
      type: "text",
      name: "email",
      placeholder: "Email",
    },
    {
      label: "Password",
      type: "text",
      name: "password",
      placeholder: "Password",
    },
    {
      label: "Client",
      type: "select",
      name: "clientId",
      options: clientList,
      placeholder: "Client",
      required: false,
    },
    {
      label: "Distributor",
      type: "select",
      name: "distributorId",
      options: distributorList,
      placeholder: "Distributor",
      required: false,
    },
    {
      label: "Role",
      type: "select",
      name: "role",
      options: rolesList,
      placeholder: "Role",
    },
    {
      label: "Contact Number",
      type: "text",
      name: "contactNumber",
      placeholder: "Contact Number",
    },
  ],
  recipe: [
    {
      label: "Field Name",
      type: "text",
      name: "FieldName",
      placeholder: "Field Name",
    },
    {
      label: "Product E",
      type: "text",
      name: "Product_e",
      placeholder: "Product E",
    },
    {
      label: "Product Cost/KG",
      type: "text",
      name: "ProductCostPerKg",
      placeholder: "Product Cost/KG",
    },
    {
      label: "Product Extra Cost",
      type: "text",
      name: "ProductExtraCost",
      placeholder: "Product Extra Cost",
    },
    {
      label: "Product T1SP",
      type: "text",
      name: "ProductT1SP",
      placeholder: "Product T1SP",
    },
    {
      label: "Product Target Speed",
      type: "text",
      name: "ProductTargetSpeed",
      placeholder: "Product Target Speed",
    },
    {
      label: "Product Tolerance",
      type: "text",
      name: "ProductTolerance",
      placeholder: "Product Tolerance",
    },
    {
      label: "Product Weight",
      type: "text",
      name: "ProductWeight",
      placeholder: "Product Weight",
    },
    {
      label: "Count Threshold",
      type: "text",
      name: "CountThreshold",
      placeholder: "Count Threshold",
    },
    {
      label: "Adj Max",
      type: "text",
      name: "AdjMax",
      placeholder: "Adj Max",
    },
    {
      label: "Adj Min",
      type: "text",
      name: "AdjMin",
      placeholder: "Adj Min",
    },
    {
      label: "AdjQtPercStart",
      type: "text",
      name: "AdjQtPercStart",
      placeholder: "AdjQtPercStart",
    },
    {
      label: "AutoAdjustON",
      type: "text",
      name: "AutoAdjustON",
      placeholder: "AutoAdjustON",
    },
    {
      label: "AdjStartWeigh",
      type: "text",
      name: "AdjStartWeigh",
      placeholder: "AdjStartWeigh",
    },
    {
      label: "CwProgramNumber",
      type: "text",
      name: "CwProgramNumber",
      placeholder: "CwProgramNumber",
    },
    {
      label: "CwBypass",
      type: "text",
      name: "CwBypass",
      placeholder: "CwBypass",
    },
    {
      label: "Tare",
      type: "text",
      name: "Tare",
      placeholder: "Tare",
    },
  ],
};
