import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";
import { baseUrl } from "constants/url.js";
import authHeader from "services/auth-header.js";
import Plot from "react-plotly.js";

function DaySummaryDetailsModal({ isOpen, toggle, rowData, clientId }) {
  const [plotData, setPlotData] = useState(null);
  const [barChartData, setBarChartData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log("Effect triggered with:", { rowData, clientId }); // Debug log

    if (!rowData || !clientId) {
      console.log("Missing required props, skipping fetch"); // Debug log
      return;
    }

    const fetchData = async () => {
      setLoading(true);
      try {
        console.log("Fetching data with:", { rowData, clientId }); // Debug log

        const response = await axios.post(
          `${baseUrl}/api/admin/day-summary-modal-data`,
          {
            ...rowData,
            clientId,
          },
          {
            headers: authHeader(),
          }
        );

        console.log("Raw API Response:", response.data); // Debug log

        // Process time series data
        const timeSeriesData = {
          data: [
            {
              type: "scatter",
              mode: "lines",
              name: "Speed",
              x: response.data.timeSeriesData.map((d) => d.timestamp),
              y: response.data.timeSeriesData.map((d) => d.speed),
              line: { color: "#0ca6f2" },
            },
            {
              type: "scatter",
              mode: "markers+text",
              name: "Recipe Changes",
              x: response.data.timeSeriesData
                .filter((d) => d.isRecipeChange)
                .map((d) => d.timestamp),
              y: response.data.timeSeriesData
                .filter((d) => d.isRecipeChange)
                .map((d) => d.speed),
              text: response.data.timeSeriesData
                .filter((d) => d.isRecipeChange)
                .map((d) => `${d.recipe}`),
              textposition: "top center",
              marker: {
                symbol: "triangle-up",
                size: 12,
                color: "#ff6384",
              },
            },
          ],
          layout: {
            title: "Speed and Recipe Changes Over Time",
            xaxis: { title: "Time", type: "date" },
            yaxis: { title: "Speed" },
            height: 400,
            margin: { l: 50, r: 50, t: 50, b: 50 },
            paper_bgcolor: "rgb(36,41,40)",
            plot_bgcolor: "rgb(36,41,40)",
            font: {
              family: "Open Sans, sans-serif",
              size: 13,
              color: "white",
            },
          },
        };

        // Process bar chart data
        const barData = {
          data: [
            {
              type: "bar",
              x: response.data.barChartData.map((d) => d.x),
              y: response.data.barChartData.map((d) => d.y),
              marker: { color: "#36a2eb" },
            },
          ],
          layout: {
            // title: "Recipe Usage Distribution",
            xaxis: {
              title: "Recipe",
              tickangle: -45,
            },
            yaxis: { title: "Count" },
            height: 300,
            margin: { l: 50, r: 50, t: 50, b: 100 },
            paper_bgcolor: "rgb(36,41,40)",
            plot_bgcolor: "rgb(36,41,40)",
            font: {
              family: "Open Sans, sans-serif",
              size: 13,
              color: "white",
            },
          },
        };

        console.log("Processed Data:", { timeSeriesData, barData }); // Debug log

        setPlotData(timeSeriesData);
        setBarChartData(barData);
        setLoading(false);

        // Verify state updates
        console.log("States after update:", {
          plotData: timeSeriesData,
          barChartData: barData,
          loading: false,
        });
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [rowData, clientId]);

  // Debug render
  console.log("Render state:", { plotData, barChartData, loading });

  // Helper function to get icon based on key
  const getIcon = (key) => {
    const iconMap = {
      Day: "far fa-calendar-alt",
      Machine: "fas fa-industry",
      Tool: "fas fa-tools",
      Product: "fas fa-box",
      "Number of Packs Packed": "fas fa-boxes",
      "Average Packs Per Minute": "fas fa-tachometer-alt",
      "Run Time": "fas fa-play-circle",
      "Idle Time": "fas fa-pause-circle",
      // Add more mappings as needed
    };
    return iconMap[key] || "fas fa-info-circle"; // default icon
  };

  // Format the value based on the key
  const formatValue = (key, value) => {
    if (typeof value === "number") {
      if (key.includes("Time")) {
        return `${Math.floor(value / 60)}h ${value % 60}m`;
      }
      return value.toLocaleString();
    }
    return value;
  };

  // Split data into two rows
  const entries = Object.entries(rowData);
  const midPoint = Math.ceil(entries.length / 2);
  const firstRow = entries.slice(0, midPoint);
  const secondRow = entries.slice(midPoint);

  const config = {
    displayModeBar: false,
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      backdropClassName="modal-backdrop-opacity"
      contentClassName="modal-bg px-4"
      className="modal-width"
    >
      <ModalHeader className="p-3 pt-5" toggle={toggle}>
        <h2 className="" style={{ color: "#0ca6f2" }}>
          {" "}
          Details for {rowData.Day}
        </h2>
      </ModalHeader>
      <ModalBody className="p-3">
        {[firstRow, secondRow].map((row, rowIndex) => (
          <Row key={rowIndex}>
            {row.map(([key, value]) => (
              <Col md="3" key={key} className="mb-3">
                <Card
                  className="card-stats"
                  style={{ backgroundColor: "#27293d" }}
                >
                  <CardBody>
                    <Row className="align-items-center">
                      <Col md="3">
                        <div
                          className="icon-big text-center"
                          style={{ color: "#0ca6f2" }}
                        >
                          <i className={getIcon(key)} />
                        </div>
                      </Col>
                      <Col md="9">
                        <div>
                          <p className="text-muted mb-1 small">{key}</p>
                          <h4 className="mb-0" style={{ color: "#ffffff" }}>
                            {formatValue(key, value)}
                          </h4>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        ))}
        {loading ? (
          <div>Loading...</div>
        ) : (
          <div>
            {plotData && (
              <Card className="shadow mb-3">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h2 className="mb-0 text-muted">
                        Speed and Machine State
                      </h2>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart" style={{ height: "auto" }}>
                    <div className="chart-container-bar">
                      {plotData.data && (
                        <Plot
                          data={plotData.data}
                          className="w-100 h-100"
                          useResizeHandler
                          layout={plotData.layout}
                          config={config}
                        />
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            )}
            {barChartData && (
              <Card className="shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h2 className="mb-0 text-muted">
                        Machine State Distribution
                      </h2>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart" style={{ height: "auto" }}>
                    <div className="chart-container-bar">
                      {barChartData.data && (
                        <Plot
                          data={barChartData.data}
                          className="w-100 h-100"
                          useResizeHandler
                          layout={barChartData.layout}
                          config={config}
                        />
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            )}
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          size="sm"
          style={{ backgroundColor: "#0ca6f2" }}
          onClick={toggle}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default DaySummaryDetailsModal;
