import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  FormGroup,
  Input,
  Form,
  Spinner,
} from "reactstrap";
import { modalConfiguration } from "../../variables/modalConfiguration";
import axios from "axios";
import { baseUrl } from "../../constants/url";
import authHeader from "../../services/auth-header";
import CustomSelectComponent from "components/CustomSelect/CustomSelect";
import { toast } from "react-toastify";

function AddNewModal({
  isOpen,
  toggle,
  modalKey,
  modalTitle,
  modalData,
  modalButtonText,
  fetchData,
  user,
}) {
  const [modalList, setModalList] = useState(null);
  const [modalState, setModalState] = useState(null);
  const [isAdding, setIsAdding] = useState(false);

  const modalListInitState = (modalListArray) => {
    let tempState = {};
    modalListArray &&
      modalListArray.forEach((item) => {
        if (typeof item.name === "string") {
          tempState[item.name] = "";
        } else {
          item.name.forEach((key) => {
            tempState[key] = "";
          });
        }
      });
    return tempState;
  };

  useEffect(() => {
    if (modalKey) {
      setModalList(modalConfiguration[modalKey]);
      if (modalData) {
        setModalState({ ...modalData });
      } else {
        let tempState = modalListInitState(modalConfiguration[modalKey]);
        // * If its Add User modal, check if client is present in user object. If yes then set it on state
        if (
          modalKey === "user" &&
          modalTitle === "Add User" &&
          user?.clientId
        ) {
          tempState.clientId = user.clientId;
        }
        if (
          modalKey === "user" &&
          modalTitle === "Add User" &&
          user?.role === "clientAdmin"
        ) {
          tempState.role = 2;
        }
        setModalState(tempState);
      }
    }
  }, [modalKey, modalData]);

  const onChange = (event) => {
    console.log("event.target.name", event.target.name);
    console.log("event.target.value", event.target.value);
    if (
      (modalTitle === "Add Machine Type" ||
        modalTitle === "Update Machine Type") &&
      event.target.type === "file"
    ) {
      setModalState((prevState) => {
        return {
          ...prevState,
          [event.target.name]: event.target.files[0],
        };
      });
    } else if (
      modalTitle === "Update Client" &&
      event.target.name === "is_active"
    ) {
      setModalState((prevState) => {
        return {
          ...prevState,
          [event.target.name]: event.target.value
            ? JSON.parse(event.target.value)
            : event.target.value,
        };
      });
    } else {
      setModalState((prevState) => {
        if (event.target.name === "clientId" && modalTitle === "Add User") {
          return {
            ...prevState,
            [event.target.name]: event.target.value,
            role: "",
          };
        } else if (
          event.target.name === "role" &&
          modalTitle === "Add User" &&
          event.target.value === "6" // 6 is distributor role id
        ) {
          return {
            ...prevState,
            [event.target.name]: event.target.value,
            clientId: "",
          };
        } else {
          return {
            ...prevState,
            [event.target.name]: event.target.value,
          };
        }
      });
    }
  };

  const closeModal = () => {
    setModalState(modalListInitState(modalConfiguration[modalKey]));
    toggle();
  };

  const addNew = async (e) => {
    e.preventDefault();
    // * Check any required field is empty
    let requiredField = modalList.find(
      (item) =>
        item.required &&
        !modalTitle.includes("Update") &&
        !modalState[item.name]
    );

    if (requiredField) {
      toast.error(`${requiredField.label} is required`);
      return;
    }
    setIsAdding(true);
    try {
      if (modalTitle === "Add Distributor") {
        await axios.post(`${baseUrl}/api/admin/distributor`, modalState, {
          headers: authHeader(),
        });
      } else if (modalTitle === "Update Distributor") {
        await axios.put(
          `${baseUrl}/api/admin/distributor/${modalData.id}`,
          modalState,
          {
            headers: authHeader(),
          }
        );
      } else if (modalTitle === "Add Client") {
        await axios.post(`${baseUrl}/api/admin/client`, modalState, {
          headers: authHeader(),
        });
      } else if (modalTitle === "Update Client") {
        if (modalState.is_active === "") {
          setIsAdding(false);
          toast.error("Please select Enabled value!");
          return;
        }
        await axios.put(
          `${baseUrl}/api/admin/client/update/${modalData.id}`,
          modalState,
          {
            headers: authHeader(),
          }
        );
      } else if (modalTitle === "Add Machine") {
        await axios.post(`${baseUrl}/api/admin/machine`, modalState, {
          headers: authHeader(),
        });
      } else if (modalTitle === "Update Machine") {
        await axios.put(
          `${baseUrl}/api/admin/machine/${modalData.id}`,
          modalState,
          {
            headers: authHeader(),
          }
        );
      } else if (modalTitle === "Add Machine Type") {
        let formData = new FormData();
        formData.append("label", modalState["label"]);
        formData.append("file", modalState["file"]);

        await axios.post(`${baseUrl}/api/admin/machineTypes`, formData, {
          headers: authHeader(),
        });
      } else if (modalTitle === "Update Machine Type") {
        let formData = new FormData();
        formData.append("label", modalState["label"]);
        formData.append("file", modalState["file"]);

        await axios.put(
          `${baseUrl}/api/admin/machineTypes/${modalData.id}`,
          formData,
          {
            headers: authHeader(),
          }
        );
      } else if (modalTitle === "Add User") {
        let modalAddUserState = {};
        if (user.role === "clientAdmin") {
          modalAddUserState = {
            ...modalState,
            clientId: user.clientId,
            role: 2,
          };
        } else {
          modalAddUserState = {
            ...modalState,
          };
        }
        await axios.post(`${baseUrl}/api/admin/user`, modalAddUserState, {
          headers: authHeader(),
        });
      } else if (modalTitle === "Update User") {
        await axios.put(
          `${baseUrl}/api/admin/user/${modalData.id}`,
          modalState,
          {
            headers: authHeader(),
          }
        );
      } else if (modalTitle === "Update Line") {
        await axios.put(
          `${baseUrl}/api/admin/updateLine/${modalData.id}`,
          modalState,
          {
            headers: authHeader(),
          }
        );
      }
      fetchData();
      setIsAdding(false);
      toast.success("Success!");
      closeModal();
    } catch (err) {
      console.log("ERROR");
      console.log(err);
      toast.error(
        err.response?.data?.message || err.message || "Something went wrong"
      );
      setIsAdding(false);
    }
  };

  const isDisabledTextField = (item) => {
    return (
      (modalTitle === "Update User" && item.label === "Password") ||
      (modalTitle === "Update User" && item.label === "Username") ||
      (modalTitle === "Update Client" &&
        ["clientId", "name"].includes(item.name)) ||
      (modalTitle === "Update Machine Type" && item.name === "label") ||
      (modalTitle === "Update Machine" && item.label === "Client") ||
      (modalTitle === "Update Distributor" && item.label === "Name")
    );
  };

  const isDisabledSelect = (item) => {
    console.log("modalTitle", modalTitle);
    console.log("item.label", item.label);
    if (modalTitle === "Update Machine") return item.label === "Client";
    if (user?.role === "clientAdmin") return item.label === "Client";
    if (modalTitle === "Update User")
      return item.label === "Client" || item.label === "Role";
    if (modalTitle === "Add User" && modalState["role"] === "6")
      return item.label === "Client";
    return false;

    // return (modalTitle === "Update User" &&
    //   (item.label === "Client" ||
    //     item.label === "Role")) ||
    //   (user?.role === "clientAdmin" &&
    //     (item.label === "Client" ||
    //       item.label === "Role")) ||
    //   (modalTitle === "Update Machine" &&
    //     item.label === 'Client')
  };

  return (
    <Modal
      centered
      scrollable
      size="lg"
      isOpen={isOpen}
      toggle={toggle}
      contentClassName="modal-bg"
      backdropClassName="modal-backdrop-opacity"
    >
      <ModalHeader
        toggle={toggle}
        close={
          <button className="close" onClick={toggle}>
            <div className="text-white rounded-circle">
              <i className="fas fa-times" />
            </div>
          </button>
        }
      >
        <h2 style={{ color: "#0ca6f2" }}>{modalTitle}</h2>
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={addNew} id="form">
          <div className="pl-lg-4">
            <Row>
              {modalList &&
                modalList.map((item, idx) => {
                  if (item.type === "text") {
                    return (
                      <Col lg="3" key={idx}>
                        <FormGroup className="mb-2">
                          <label className="form-control-label textWhite d-block">
                            {item.label}{" "}
                            {Boolean(item.required) &&
                              !isDisabledTextField(item) &&
                              !modalTitle.includes("Update") && (
                                <span className="required-label">*</span>
                              )}
                          </label>
                          <Input
                            placeholder={item.placeholder}
                            name={item.name}
                            type={
                              item.label === "Password" ? "password" : "text"
                            }
                            className={
                              isDisabledTextField(item)
                                ? "textBg-disabled"
                                : "textBg"
                            }
                            disabled={isDisabledTextField(item)}
                            value={modalState[item.name]}
                            onChange={onChange}
                            required={
                              Boolean(item.required) &&
                              !isDisabledTextField(item) &&
                              !modalTitle.includes("Update")
                            }
                          />
                        </FormGroup>
                      </Col>
                    );
                  } else if (item.type === "select") {
                    return (
                      <CustomSelectComponent
                        item={item}
                        idx={idx}
                        dontUseEmptyOption={item.dontUseEmptyOption}
                        disabled={isDisabledSelect(item)}
                        className={
                          isDisabledSelect(item) ? "textBg-disabled" : "textBg"
                        }
                        value={
                          user?.role === "clientAdmin" &&
                          item.label === "Client"
                            ? user?.clientId
                            : // : user?.role === "clientAdmin" &&
                              //   item.label === "Role"
                              //   ? 2
                              modalState[item.name]
                        }
                        onChange={onChange}
                        required={
                          Boolean(item.required) &&
                          !isDisabledSelect(item) &&
                          !modalTitle.includes("Update")
                        }
                        isDeviceRoleDisabled={
                          parseInt(modalState["clientId"]) !== 1
                        } // for user role assignment if client is not pal, device role is disabled.
                        isAdminRoleDisabled={
                          parseInt(modalState["clientId"]) !== 1
                        } // for user role assignment if client is not pal, admin role is disabled.
                      />
                    );
                  } else if (item.type === "select_local") {
                    return (
                      <Col lg="3" key={idx}>
                        <FormGroup className="mb-2">
                          <label className="form-control-label textWhite d-block">
                            {item.label}{" "}
                            {Boolean(item.required) &&
                              !modalTitle.includes("Update") && (
                                <span className="required-label">*</span>
                              )}
                          </label>
                          <Input
                            required={
                              Boolean(item.required) &&
                              !modalTitle.includes("Update")
                            }
                            placeholder={item.placeholder}
                            name={item.name}
                            type="select"
                            disabled={
                              modalTitle === "Update User" &&
                              (item.label === "Client" || item.label === "Role")
                            }
                            className="textBg"
                            value={modalState[item.name]}
                            onChange={onChange}
                          >
                            {item.options.map((option, idx) => {
                              return (
                                <option key={idx} value={option}>
                                  {option}
                                </option>
                              );
                            })}
                          </Input>
                        </FormGroup>
                      </Col>
                    );
                  } else if (item.type === "date") {
                    return (
                      <Col lg="3" key={idx}>
                        <FormGroup className="mb-2">
                          <label className="form-control-label mb-0 d-block">
                            {item.label}{" "}
                            {Boolean(item.required) &&
                              !modalTitle.includes("Update") && (
                                <span className="required-label">*</span>
                              )}
                          </label>
                          <Input
                            name={item.name}
                            placeholder={item.placeholder}
                            type="date"
                            value={modalState[item.name]}
                            onChange={onChange}
                            required={
                              Boolean(item.required) &&
                              !modalTitle.includes("Update")
                            }
                          />
                        </FormGroup>
                      </Col>
                    );
                  } else if (item.type === "number") {
                    return (
                      <Col lg="3" key={idx}>
                        <FormGroup className="mb-2">
                          <label className="form-control-label mb-0 d-block">
                            {item.label}{" "}
                            {Boolean(item.required) &&
                              !modalTitle.includes("Update") && (
                                <span className="required-label">*</span>
                              )}
                          </label>
                          <Input
                            name={item.name}
                            placeholder={item.placeholder}
                            type="number"
                            value={modalState[item.name]}
                            onChange={onChange}
                            required={
                              Boolean(item.required) &&
                              !modalTitle.includes("Update")
                            }
                          />
                        </FormGroup>
                      </Col>
                    );
                  } else if (item.type === "file" && item.name === "fileCsv") {
                    return (
                      <Col lg="3" key={idx}>
                        <FormGroup className="mb-2">
                          <label className="form-control-label mb-2 d-block text-muted">
                            {item.label}{" "}
                            {Boolean(item.required) &&
                              !modalTitle.includes("Update") && (
                                <span className="required-label">*</span>
                              )}
                          </label>
                          <label
                            style={{
                              display: "block",
                              width: "100%",
                              height: "calc(1.5em + 1.25rem + 2px)",
                              padding: "0.625rem 0.75rem",
                              fontSize: "0.875rem",
                              fontWeight: 400,
                              lineHeight: 1.5,
                              // backgroundColor: "#19312e !important",
                              color: "white !important",
                              backgroundClip: "padding-box",
                              border: "1px solid #cad1d7",
                              borderRadius: "0.375rem",
                              boxShadow: "none",
                              // wordWrap: 'break-word',
                              overflow: "hidden",
                              transition:
                                "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                            }}
                            className="btn btn-primary"
                            htmlFor="fileCsv"
                          >
                            {/* {item.label} */}
                            {modalState[item.name].row1
                              ? "File attached"
                              : "Upload"}
                          </label>
                          <Input
                            name={item.name}
                            placeholder={item.placeholder}
                            type="file"
                            id="fileCsv"
                            accept=".csv"
                            onChange={onChange}
                            onClick={(e) => {
                              e.target.value = null;
                            }}
                            className="d-none"
                            required={
                              Boolean(item.required) &&
                              !modalTitle.includes("Update")
                            }
                          />
                        </FormGroup>
                      </Col>
                    );
                  } else if (item.type === "file" && item.name === "file") {
                    return (
                      <Col lg="3" key={idx}>
                        <FormGroup className="mb-2">
                          <label className="form-control-label mb-2 d-block text-muted">
                            {item.label}{" "}
                            {Boolean(item.required) &&
                              !modalTitle.includes("Update") && (
                                <span className="required-label">*</span>
                              )}
                          </label>
                          <label
                            style={{
                              display: "block",
                              width: "100%",
                              height: "calc(1.5em + 1.25rem + 2px)",
                              padding: "0.625rem 0.75rem",
                              fontSize: "0.875rem",
                              fontWeight: 400,
                              lineHeight: 1.5,
                              // backgroundColor: "#19312e !important",
                              color: "white !important",
                              backgroundClip: "padding-box",
                              border: "1px solid #cad1d7",
                              borderRadius: "0.375rem",
                              boxShadow: "none",
                              // wordWrap: 'break-word',
                              overflow: "hidden",
                              transition:
                                "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                            }}
                            className="btn btn-primary"
                            htmlFor="file"
                          >
                            {/* {item.label} */}
                            {modalState[item.name] ? "File attached" : "Upload"}
                          </label>
                          <Input
                            name={item.name}
                            placeholder={item.placeholder}
                            type="file"
                            id="file"
                            accept="*"
                            onChange={onChange}
                            onClick={(e) => {
                              console.log(e);
                              e.target.value = null;
                            }}
                            className="d-none"
                            // required={Boolean(item.required) && !modalTitle.includes('Update')}
                          />
                        </FormGroup>
                      </Col>
                    );
                  } else return null;
                })}
            </Row>
          </div>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button onClick={closeModal}>Cancel</Button>
        <Button
          style={{ backgroundColor: "#0ca6f2", border: "none" }}
          // onClick={addNew}
          type="submit"
          form="form"
        >
          {isAdding ? (
            <>
              Adding...
              <Spinner className="ml-2" size="sm" />
            </>
          ) : (
            modalButtonText
          )}
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
}

export default AddNewModal;
